import React, { createContext, useContext, useState } from 'react';

// Create context
const IframeContext = createContext();

// context provider
export const IframeProvider = ({ children }) => {
  const [iframeKey, setIframeKey] = useState(0);

  const reloadIframe = () => {
    setIframeKey(prevKey => prevKey + 1);
  };

  return (
    <IframeContext.Provider value={{ iframeKey, reloadIframe }}>
      {children}
    </IframeContext.Provider>
  );
};

// Custom hook to use context
export const useIframeContext = () => {
  return useContext(IframeContext);
};
