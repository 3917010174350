import STableHeader from "./table.header";
import STableBody from "./table.body";
import { Card } from "../../../ui/card";

const TableZero = ({ user }) => {
  return (
    <Card className="w-full">
      <STableHeader user={user} />
      <STableBody />
    </Card>
  );
};

export default TableZero;
