import axiosInstance from '../lib/axiosInstance';

export const createCustomer = params => {
  return axiosInstance.post(`/admin/conekta-customer`, params);
};

export const createSuscriptions = params => {
  return axiosInstance.post(`/admin/suscriptions`, params);
};

export const fetchConektaCustomer = userId => {
  return axiosInstance.get(`/admin/users/${userId}/conekta`);
};

export const fetchConektaCharges = search => {
  return axiosInstance.get(`/admin/conekta-customer/charges/${search}`);
};
