import { Outlet } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Header from '../components/common/header';
import { IframeProvider } from '../hooks/useIframeContext';
import { setupAxiosInterceptors } from '../lib/axiosInstance';
import { useEffect } from 'react';
const Root = () => {
  const navigate = useNavigate();

  useEffect(() => {
    setupAxiosInterceptors(navigate);
  }, [navigate]);

  return (
    <IframeProvider>
      <div className="flex min-h-screen w-full flex-col">
        <Header />
        <Outlet />
      </div>
    </IframeProvider>
  );
}

export default Root;
