import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Input } from "../../ui/input";
import { Label } from "../../ui/label";
import { Textarea } from "../../ui/textarea";
import Loading from "../../ui/loading";
import { fetchSectionAttribute } from "../../../services/section-attributes";
import { SectionAttributeContext } from "../../../providers/SectionAttributeProvider";

const AboutForm = ({ section, isSubmitting }) => {
  const [isLoading, setIsloading] = useState(true);
  const { sectionAttribute, setSectionAttribute } = useContext(
    SectionAttributeContext
  );

  const getSectionAttribute = useCallback(async () => {
    if (section) {
      try {
        const response = await fetchSectionAttribute(section);
        setSectionAttribute(response.data);
        setIsloading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  }, [section, setSectionAttribute]);

  const shouldFetch = useMemo(
    () => Object.keys(sectionAttribute).length === 0,
    [sectionAttribute]
  );

  useEffect(() => {
    if (shouldFetch) {
      getSectionAttribute();
    }
  }, [shouldFetch, getSectionAttribute]);

  const handleUpdate = (e) => {
    const { name, value } = e.target;
    setSectionAttribute((prevState) => {
      // Split name on levels if is nested, i.e: data.article.title
      const keys = name.split(".");

      // Recursive function to create the new nested state
      const updateNestedState = (obj, keys, value) => {
        const [firstKey, ...restKeys] = keys;
        if (restKeys.length === 0) {
          // last key, update value
          return {
            ...obj,
            [firstKey]: value,
          };
        }
        // No final key, continue to nest
        return {
          ...obj,
          [firstKey]: updateNestedState(obj[firstKey], restKeys, value),
        };
      };

      // If key is nested, start from prevState.data
      if (keys[0] === "data") {
        return {
          ...prevState,
          data: updateNestedState(prevState.data, keys.slice(1), value),
        };
      }

      // If key is not nested, update first level
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  if (isLoading)
    return (
      <div className="w-full">
        <Loading />
      </div>
    );

  return (
    <form className="grid w-full items-start gap-6">
      <fieldset className="grid gap-6 rounded-lg border p-4">
        <legend className="-ml-1 px-1 text-sm font-medium">Acerca de</legend>
        <div className="grid gap-3">
          <Label htmlFor="Título">Título</Label>
          <Input
            id="data.title"
            type="text"
            name="data.title"
            placeholder="Escribe el titulo de acerca de"
            value={sectionAttribute.data?.title}
            onChange={handleUpdate}
            disabled={isSubmitting}
          />
        </div>
        <div className="grid grid-cols-1 gap-4">
          <div className="grid gap-3">
            <Label htmlFor="top-p">Descripción</Label>
            <Textarea
              id="data.description"
              type="text"
              name="data.description"
              placeholder="Escribe una descripción"
              value={sectionAttribute.data?.description}
              onChange={handleUpdate}
              className="min-h-[10.5rem]"
              disabled={isSubmitting}
            />
          </div>
        </div>
      </fieldset>
    </form>
  );
};

export default AboutForm;
