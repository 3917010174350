import { useState } from "react";
import ConektaCheckout from "../../../common/ConektaCheckout";
import { Button } from "../../../ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../../ui/dialog";
import { CardDescription, CardHeader, CardTitle } from "../../../ui/card";

const STableHeader = ({ user }) => {
  const [open, setOpen] = useState(false);
  return (
    <CardHeader>
      <CardTitle>
        <div className="flex justify-between items-center">
          <div>Suscripciones</div>
          <div onClick={() => setOpen(!open)} className="flex space-x-1">
            <Dialog open={open}>
              <DialogTrigger asChild>
                <Button onClick={() => setOpen(!open)}>Suscribirse</Button>
              </DialogTrigger>
              <DialogContent className="sm:max-w-[425px]">
                <DialogHeader className="pt-6 px-6">
                  <DialogTitle>Método de pago</DialogTitle>
                  <DialogDescription>
                    Agrega un nuevo método de pago a tu cuenta.
                  </DialogDescription>
                </DialogHeader>
                <ConektaCheckout user={user} setOpen={setOpen} />
              </DialogContent>
            </Dialog>
          </div>
        </div>
      </CardTitle>
      <CardDescription>Historial de pagos</CardDescription>
    </CardHeader>
  );
};

export default STableHeader;
