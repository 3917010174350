import { useParams } from 'react-router-dom';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '../components/ui/card';
import ActivateForm from '../components/forms/activate/ActivateForm';

const ActivateAccount = () => {
  const { token } = useParams();

  return (
    <div className="w-full h-screen flex items-center justify-center px-4 theme-zinc">
      <Card className="w-full max-w-sm">
        <CardHeader>
          <CardTitle className="text-2xl">Activa tu cuenta</CardTitle>
          <CardDescription>
            Escribe tu nueva contraseña para activar tu cuenta de medflix.
          </CardDescription>
        </CardHeader>
        <CardContent className="grid gap-4">
          <ActivateForm aToken={token} />
        </CardContent>
      </Card>
    </div>
  );
};

export default ActivateAccount;