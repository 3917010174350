import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Input } from '../../ui/input';
import { Label } from '../../ui/label';
import Loading from '../../ui/loading';
import { fetchSectionAttribute } from '../../../services/section-attributes';
import { SectionAttributeContext } from '../../../providers/SectionAttributeProvider';

const ContactForm = ({ 
  section,
  isSubmitting
}) => {

  const [isLoading, setIsloading] = useState(true);
  const { sectionAttribute, setSectionAttribute } = useContext(SectionAttributeContext);

  const getSectionAttribute = useCallback(async () => {
    if (section) {
      try {
        const response = await fetchSectionAttribute(section);
        setSectionAttribute(response.data);
        setIsloading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  }, [section, setSectionAttribute]);
  
  const shouldFetch = useMemo(() => Object.keys(sectionAttribute).length === 0, [sectionAttribute]);

  useEffect(() => {
    if (shouldFetch) {
      getSectionAttribute();
    }
  }, [shouldFetch, getSectionAttribute]);

  const handleUpdate = e => {
    const { name, value } = e.target;

    setSectionAttribute(prevState => {
      // Split the name on level if is nested, i.e.: "data.services[0].name"
      const keys = name.split('.');

      // Recursive function to create the new nested state
      const updateNestedState = (obj, keys, value) => {
        const [firstKey, ...restKeys] = keys;
        if (restKeys.length === 0) {
          // Last key, update the value
          return {
            ...obj,
            [firstKey]: value
          };
        }

        // Handle arrays inside data
        if (firstKey.endsWith(']')) {
          // Get the array key, i.e: "services[0]"
          const arrayKey = firstKey.slice(0, firstKey.indexOf('['));
          const index = parseInt(firstKey.slice(firstKey.indexOf('[') + 1, firstKey.indexOf(']')), 10);

          return {
            ...obj,
            [arrayKey]: obj[arrayKey].map((item, idx) => 
              idx === index 
                ? updateNestedState(item, restKeys, value)
                : item
            )
          };
        }

        // No final key, keep nesting
        return {
          ...obj,
          [firstKey]: updateNestedState(obj[firstKey], restKeys, value)
        };
      };

      // If key is nested, start from prevState.data
      if (keys[0] === 'data') {
        return {
          ...prevState,
          data: updateNestedState(prevState.data, keys.slice(1), value)
        };
      }

      // If key is not nested, update to superior level
      return {
        ...prevState,
        [name]: value
      };
    });
  };

  if (isLoading) return (
    <div className="w-full">
      <Loading />
    </div>
  );

  return (
    <form className="grid w-full items-start gap-6">
      <fieldset className="grid gap-6 rounded-lg border p-4">
        <legend className="-ml-1 px-1 text-sm font-medium">
          Contactos
        </legend>
        <div className="grid gap-3">
          <Label htmlFor="Nombre">Contacto I - Nombre</Label>
          <Input
            id="data.contact.items.0.name"
            type="text"
            name="data.contact.items[0].name"
            placeholder="Escribe el titulo de Contacto"
            value={sectionAttribute?.data?.contact?.items?.[0]?.name}
            onChange={handleUpdate}
            disabled={isSubmitting} />
        </div>
        <div className="grid grid-cols-1 gap-4">
          <div className="grid gap-3">
            <Label htmlFor="top-p">Contacto I - Email</Label>
            <Input
              id="data.contact.items.0.email"
              type="text"
              name="data.contact.items[0].email"
              placeholder="Escribe el email de Contacto"
              value={sectionAttribute?.data?.contact?.items?.[0]?.email}
              onChange={handleUpdate}
              disabled={isSubmitting} />
          </div>
        </div>
        <hr />
        <div className="grid gap-3">
          <Label htmlFor="Nombre">Contacto II - Nombre</Label>
          <Input
            id="data.contact.items.1.name"
            type="text"
            name="data.contact.items[1].name"
            placeholder="Escribe el titulo de Contacto"
            value={sectionAttribute?.data?.contact?.items?.[1]?.name}
            onChange={handleUpdate}
            disabled={isSubmitting} /> 
        </div>
        <div className="grid grid-cols-1 gap-4">
          <div className="grid gap-3">
            <Label htmlFor="top-p">Contacto II - Email</Label>
            <Input
              id="data.contact.items.1.email"
              type="text"
              name="data.contact.items[1].email"
              placeholder="Escribe el email de Contacto"
              value={sectionAttribute?.data?.contact?.items?.[1]?.email}
              onChange={handleUpdate}
              disabled={isSubmitting} />
          </div>
        </div>
        <hr />
        <div className="grid gap-3">
          <Label htmlFor="Nombre">Ubicación I - Nombre</Label>
          <Input 
            id="data.location.items.0.name"
            type="text"
            name="data.location.items[0].name"
            placeholder="Escribe el titulo de Ubicación"
            value={sectionAttribute?.data?.location?.items?.[0]?.name}
            onChange={handleUpdate}
            disabled={isSubmitting} /> 
        </div>
        <div className="grid grid-cols-1 gap-4">
          <div className="grid gap-3">
            <Label htmlFor="top-p">Ubicación I - Colonia</Label>
            <Input
              id="data.location.items.0.location"
              type="text"
              name="data.location.items[0].location"
              placeholder="Escribe el location de Contacto"
              value={sectionAttribute?.data?.location?.items?.[0]?.location}
              onChange={handleUpdate}
              disabled={isSubmitting} />
          </div>
        </div>
      </fieldset>
    </form>
  );
};

export default ContactForm;