import { createBrowserRouter } from 'react-router-dom';
import AuthLayout from '../layouts/AuthLayout';
import Website from '../pages/Website';
import Login from '../pages/Login';
import Root from '../layouts/Root';
import PrivateRoute from '../routes/PrivateRoute';
import ActivateAccount from '../pages/ActivateAccount';
import Profile from '../pages/Profile';

export const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <PrivateRoute>
        <Root />
      </PrivateRoute>
    ),
    children: [
      {
        path: "/",
        element: <Website />,
      },
      {
        path: "/website",
        element: <Website />,
      },
      {
        path: "/profile",
        element: <Profile />,
      },
    ],
  },
  {
    path: "auth",
    element: <AuthLayout />,
    children: [
      {
        path: "login",
        element: <Login />,
      },
      {
        path: "activate-account/:token",
        element: <ActivateAccount />,
      },
    ],
  },
]);
