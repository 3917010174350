import { CardContent } from "../../../ui/card";
import {
  Table,
  TableBody,
  TableCaption,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../ui/table";

const STableBody = () => {
  return (
    <CardContent className="space-y-4">
      <Table>
        <TableCaption>Sin pagos.</TableCaption>
        <TableHeader>
          <TableRow>
            <TableHead className="w-[100px]">Plan</TableHead>
            <TableHead>Tarjeta</TableHead>
            <TableHead>Estatus</TableHead>
            <TableHead>Fecha</TableHead>
            <TableHead className="text-right">Monto (MXN)</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody></TableBody>
      </Table>
    </CardContent>
  );
};

export default STableBody;
