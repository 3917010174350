import ApiClient from './ApiClient';

const headers = {
  'Accept': 'application/vnd.conekta-v2.0.0+json',
  'Accept-Language': 'es',
  'Content-Type': 'application/json',
  'Authorization': `Bearer ${process.env.REACT_APP_CONEKTA_PRIVATE_KEY}`
};

const conektaApiClient = new ApiClient(
  process.env.REACT_APP_CONEKTA_API_URL,
  headers
);

const ConektaApi = {
  // customers
  createCustomer: (data) => conektaApiClient.post('/customers', data),
  deleteCustomer: (customerId) => conektaApiClient.delete(`/customers/${customerId}`),
  getCustomers: () => conektaApiClient.get(`/customers`),
  getCustomer: (customerId) => conektaApiClient.get(`/customers/${customerId}`),
  updateCustomer: (customerId, data) => conektaApiClient.put(`/customers/${customerId}`, data),
  // plans
  createPlan: (data) => conektaApiClient.post('/plans', data),
  deletePlan: (planId) => conektaApiClient.delete(`/plans/${planId}`),
  getPlans: () => conektaApiClient.get(`/plans`),
  getPlan: (planId) => conektaApiClient.get(`/plans/${planId}`),
  updatePlan: (planId, data) => conektaApiClient.put(`/plans/${planId}`, data),
  // subscriptions
  createSubscription: (customerId, data) => conektaApiClient.post(`/customers/${customerId}/subscription`, data),
  pauseSubscription: (customerId) => conektaApiClient.post(`/customers/${customerId}/subscription/pause`),
  resumeSubscription: (customerId) => conektaApiClient.post(`/customers/${customerId}/subscription/resume`),
  cancelSubscription: (customerId) => conektaApiClient.post(`/customers/${customerId}/subscription/cancel`),
  getSubscription: (customerId) => conektaApiClient.get(`/customers/${customerId}/subscription`),
  getSubscriptionEvents: (customerId) => conektaApiClient.get(`/customers/${customerId}/subscription/events`),
  updateSubscription: (customerId, data) => conektaApiClient.put(`/customers/${customerId}/subscription`, data),
  // charges
  getCharges: (customerId) => conektaApiClient.get(`/charges?limit=20&search=${customerId}`),
};

export default ConektaApi;