import { useState } from 'react';
import { Loader2 } from 'lucide-react';
import { Link, useNavigate } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Button } from '../../../components/ui/button';
import { Label } from '../../../components/ui/label';
import { login } from '../../../services/auth';
import { saveToken } from '../../../lib/auth';
import { validationSchema }  from './validations';
import {
  Alert,
  AlertDescription,
  AlertTitle,
} from '../../../components/ui/alert';

const LoginForm = ({ onLogin = () => {}}) => {
  let navigate = useNavigate();
  const [error, setError] = useState('');

  const handleErrors = ({ data }) => {
    const { messages } = data;
    setError(messages?.join('. '));
  }

  const handleLogin = async (values, { setSubmitting }) => {
    const { email, password } = values;
    try {
      const { data } = await login({ email, password });
      saveToken(data.token);
      setSubmitting(false);
      navigate('/');
    } catch ({ response }) {
      setSubmitting(false);
      handleErrors(response);
    }
  };

  return (
    <Formik
      initialValues={{ email: '', password: '' }}
      validationSchema={validationSchema}
      onSubmit={handleLogin}
    >
      {({ isSubmitting }) => (
        <Form className="grid gap-4">
          {error && 
            <Alert variant="destructive">
              <AlertTitle>Error</AlertTitle>
              <AlertDescription>
                { error }
              </AlertDescription>
            </Alert>
          }
          <div className="grid gap-2">
            <Label htmlFor="email">Correo electrónico</Label>
            <Field placeholder="m@ejemplo.com" name="email" type="text" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" required/>
            <ErrorMessage name="email" component="div" className="text-red-500 text-xs italic" />
          </div>
          <div className="grid gap-2">
            <div className="flex items-center">
              <Label htmlFor="password">Contraseña</Label>
              <Link
                to="/forgot-password"
                className="ml-auto inline-block text-sm underline"
              >
                ¿Olvidaste tu contraseña?
              </Link>
            </div>
            <Field name="password" type="password" required className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline" />
            <ErrorMessage name="password" component="div" className="text-red-500 text-xs italic" />
          </div>
          <div className="flex items-center justify-between">
            <Button 
              type="submit" 
              disabled={isSubmitting} 
              className="w-full">
              Ingresar &nbsp;
              {isSubmitting && <Loader2 className="mr-2 h-4 w-4 animate-spin" /> }
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default LoginForm;
