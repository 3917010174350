export const menuProfile = [
  {
    name: "general",
    label: "General",
    active: true,
  },
  {
    name: "account",
    label: "Cuenta",
    active: true,
  },
  {
    name: "suscriptions",
    label: "Suscripciones",
    active: true,
  },
  {
    name: "social",
    label: "Redes Sociales",
    active: true,
  },
  {
    name: "support",
    label: "Soporte",
    active: false,
  },
];
