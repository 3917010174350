import { Label } from "@radix-ui/react-dropdown-menu";
import { Input } from "../ui/input";
import { Button } from "../ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../ui/card";

const AccountProfile = ({ user }) => {
  return (
    user && (
      <div className="grid gap-6 mr-14">
        <Card className="w-full">
          <CardHeader>
            <CardTitle>Perfil</CardTitle>
            <CardDescription>Datos de contacto</CardDescription>
          </CardHeader>
          <CardContent className="space-y-4">
            <div
              data-orientation="horizontal"
              role="none"
              className="shrink-0 bg-border h-[1px] w-full"
            ></div>
            <form className="space-y-8">
              <div className="space-y-2">
                <Label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                  Nombre
                </Label>
                <Input
                  id=":r15:-form-item"
                  type="text"
                  placeholder="Ingresa tu nombre..."
                  className="flex h-9 w-full rounded-md border border-input bg-transparent px-3 py-1 text-sm shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50"
                  disabled
                  value={user.name}
                />
              </div>
              <div className="space-y-2">
                <Label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                  Correo electrónico
                </Label>
                <Input
                  id=":r15:-form-item"
                  type="text"
                  placeholder="Ingresa tu correo electrónico..."
                  className="flex h-9 w-full rounded-md border border-input bg-transparent px-3 py-1 text-sm shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50"
                  disabled
                  value={user.email}
                />
              </div>
              <div className="space-y-2">
                <Label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                  Whatsapp
                </Label>
                <Input
                  id=":r15:-form-item"
                  type="text"
                  placeholder="Ingresa tu whatsapp..."
                  className="flex h-9 w-full rounded-md border border-input bg-transparent px-3 py-1 text-sm shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50"
                  disabled
                  value={user.phone}
                />
              </div>
            </form>
          </CardContent>
          <CardFooter>
            <Button className="ml-auto" disabled>
              Guardar
            </Button>
          </CardFooter>
        </Card>
      </div>
    )
  );
};

export default AccountProfile;
