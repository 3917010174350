import axiosInstance from '../lib/axiosInstance';

export const login = ({ email, password }) => {
  return axiosInstance.post(`/auth/login`, {
    email, password
  });
};

export const activateAccount = ({ token, password }) => {
  return axiosInstance.post(`/auth/activate-account`, {
    token, password
  });
};

export const tokenValidation = ({ token }) => {
  return axiosInstance.post(`/auth/validate-token`, {
    token
  });
};
