import { Loader2, Share } from 'lucide-react';
import { Button } from '../ui/button';
import WebsiteDrawer from './WebsiteDrawer';

const WebsiteHeader = ({ 
  onSubmit,
  isSubmitting
}) => {
  return (
    <header className="sticky top-0 z-10 flex h-[57px] items-center gap-1 border-b bg-background px-4">
      <h1 className="text-xl font-semibold">Editor de sitio web</h1>
      <WebsiteDrawer />
      <Button
        variant="outline"
        size="sm"
        className="ml-auto gap-1.5 text-sm"
        onClick={onSubmit}
      >
        Publicar
        {isSubmitting ? 
          <Loader2 className="mr-2 h-4 w-4 animate-spin" />
        : <Share className="size-3.5" />}
      </Button>
    </header>
  );
};

export default WebsiteHeader;