// src/hooks/useUser.js
import { useState, useEffect } from 'react';
import jwt from 'jsonwebtoken';
import { getToken } from '../lib/auth';

const decodeToken = () => {
  try {
    const token = getToken();
    const decodedToken = jwt.decode(token);
    return decodedToken;
  } catch (error) {
    console.error('Error decoding token:', error);
    return null;
  }
};

const useUser = () => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const decodedUser = decodeToken();
    setUser(decodedUser);
  }, []);

  return user;
};

export default useUser;
