import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import TableZero from "./suscriptions/zero-state/table.zero";
import ConektaSuscriptions from "./suscriptions/conekta/conekta.suscriptions";
import { fetchConektaCustomer } from "../../services/conekta";
import Loading from "../ui/loading";
import ConektaApi from "../../api/conektaApi";
import { SectionAttributeContext } from "../../providers/SectionAttributeProvider";

const SuscriptionsProfile = ({ user }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [conektaCustomer, setConektaCustomer] = useState({});
  const [suscriptionsEvents, setSuscriptionsEvents] = useState([]);
  const { shouldRerender } = useContext(SectionAttributeContext);

  const getConektaCustomer = useCallback(async () => {
    if (user) {
      try {
        const response = await fetchConektaCustomer(user.userId);
        setConektaCustomer(response.data);
        const result = await ConektaApi.getSubscriptionEvents(
          response.data.data.id
        );
        setSuscriptionsEvents(result);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  }, [user, shouldRerender]);

  const shouldFetch = useMemo(
    () => Object.keys(conektaCustomer).length === 0,
    [conektaCustomer]
  );

  useEffect(() => {
    if (shouldFetch) {
      getConektaCustomer();
    }
  }, [shouldFetch, getConektaCustomer]);

  if (isLoading) return <Loading />;

  return (
    <div className="grid gap-6 mr-14">
      {conektaCustomer.data ? (
        suscriptionsEvents?.total > 1 ? (
          <ConektaSuscriptions customerId={conektaCustomer.data.id} />
        ) : (
          <TableZero user={user} />
        )
      ) : (
        <TableZero user={user} />
      )}
    </div>
  );
};

export default SuscriptionsProfile;
