import React, { createContext, useState } from 'react';

const SectionAttributeContext = createContext();

const SectionAttributeProvider = ({ children }) => {
  const [sectionAttribute, setSectionAttribute] = useState({});
  const [section, setSection] = useState({});
  const [shouldRerender, setShouldRerender] = useState(false);

  const triggerRerender = () => {
    setShouldRerender(prev => !prev);
  };

  return (
    <SectionAttributeContext.Provider value={{ 
      sectionAttribute, 
      setSectionAttribute,
      section, 
      setSection,
      shouldRerender, 
      triggerRerender }}>
      {children}
    </SectionAttributeContext.Provider>
  );
};

export { SectionAttributeContext, SectionAttributeProvider };
