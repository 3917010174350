import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import GeneralProfile from "../components/profile/general.profile";
import SuscriptionsProfile from "../components/profile/suscriptions.profile";
import { menuProfile } from "../lib/profile";
import AccountProfile from "../components/profile/account.profile";
import useUser from "../hooks/useUser";
import SocialProfile from "../components/profile/social.profile";
import { fetchWebsiteContent } from "../services/website";

const Profile = () => {
  const user = useUser();
  const [website, setWebsite] = useState({});
  const [selected, setSelected] = useState("general");

  const selectOption = (e) => {
    e.preventDefault();
    setSelected(e.target.name);
  };

  const evalSelection = (option) =>
    option === selected ? "font-semibold bg-muted" : "";

  useEffect(() => {
    const getWebsiteContent = async () => {
      try {
        const response = await fetchWebsiteContent(user.userId);
        setWebsite(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (user) getWebsiteContent();
  }, [user]);

  return (
    <div className="flex min-h-screen w-full flex-col">
      <main className="flex min-h-[calc(100vh_-_theme(spacing.16))] flex-1 flex-col gap-4 bg-muted/40 p-4 md:gap-8">
        <div className="mx-auto grid w-full max-w-8xl gap-2">
          <h1 className="text-3xl font-semibold">Configuración</h1>
        </div>
        <div className="mx-auto grid w-full max-w-8xl items-start gap-6 md:grid-cols-[180px_1fr] lg:grid-cols-[250px_1fr]">
          <nav
            className="grid gap-4 text-sm text-muted-foreground"
            x-chunk="dashboard-04-chunk-0"
          >
            {menuProfile.map((e) =>
              e.active ? (
                <Link
                  key={e.name}
                  name={e.name}
                  onClick={selectOption}
                  className={`text-primary p-2 ${evalSelection(e.name)}`}
                >
                  {e.label}
                </Link>
              ) : null
            )}
          </nav>
          {selected === "general" ? <GeneralProfile website={website} /> : null}
          {selected === "account" ? <AccountProfile user={user} /> : null}
          {selected === "suscriptions" ? (
            <SuscriptionsProfile user={user} />
          ) : null}
          {selected === "social" ? <SocialProfile website={website} /> : null}
        </div>
      </main>
    </div>
  );
};

export default Profile;
