import { Navigate } from 'react-router-dom';
import { getToken } from '../lib/auth';
// import { tokenValidation } from '../services/auth';

const PrivateRoute = ({ children }) => {
  try {
    // TODO: validate token
    // const token = getToken();
    const isAuthenticated = getToken();
    return isAuthenticated ? children : <Navigate to="/auth/login" replace />;
  } catch {
    return <Navigate to="/auth/login" replace />;
  }
}

export default PrivateRoute;
