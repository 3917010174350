import WebsiteHeader from "../components/website/WebsiteHeader";
import WebsiteContent from "../components/website/WebsiteContent";
import useUser from "../hooks/useUser";
import { useIframeContext } from "../hooks/useIframeContext";
import { SectionAttributeContext } from "../providers/SectionAttributeProvider";
import { updateSectionAttribute } from "../services/section-attributes";
import { useContext, useState } from "react";

const Website = () => {
  const user = useUser();
  const { sectionAttribute } = useContext(SectionAttributeContext);
  const { reloadIframe } = useIframeContext();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = async () => {
    setIsSubmitting(true);
    const id = sectionAttribute._id;
    await updateSectionAttribute(id, sectionAttribute);
    setIsSubmitting(false);
    reloadIframe();
  };

  return (
    <div className="flex flex-col">
      <WebsiteHeader onSubmit={onSubmit} isSubmitting={isSubmitting} />
      <WebsiteContent user={user} isSubmitting={isSubmitting} />
    </div>
  );
};

export default Website;
