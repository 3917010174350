import { Card } from "../../../ui/card";
import LeftContainer from "./left.container";
// import RightContainer from "./righ.container";

const ConektaSuscriptions = ({ customerId }) => {
  return (
    <Card className="w-full">
      <LeftContainer customerId={customerId} />
    </Card>
  );
};

export default ConektaSuscriptions;
