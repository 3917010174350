import React, { useEffect, useState, useRef, useContext } from 'react';
import axios from 'axios';
import ConektaApi from '../../api/conektaApi';
import { createCustomer, createSuscriptions } from '../../services/conekta';
import { SectionAttributeContext } from '../../providers/SectionAttributeProvider';

const ConektaCheckout = ({ 
  user,
  setOpen
}) => {
  const { triggerRerender } = useContext(SectionAttributeContext);
  const [checkoutRequestId, setCheckoutRequestId] = useState(null);
  // Use a ref to track if the request has been made
  const requestMadeRef = useRef(false); 

  useEffect(() => {
    const fetchCheckoutRequestId = async () => {
      try {
        const response = await axios.post(
          'https://api.conekta.io/tokens',
          {
            checkout: {
              returns_control_on: 'Token'
            }
          },
          {
            headers: {
              'Accept': 'application/vnd.conekta-v2.0.0+json',
              'Content-Type': 'application/json'
            },
            auth: {
              username: process.env.REACT_APP_CONEKTA_PRIVATE_KEY
            }
          }
        );

        // Adjust according to the response structure
        setCheckoutRequestId(response.data.checkout.id); 
      } catch (error) {
        if (error.response) {
          console.error('Error response:', error.response.data);
        } else {
          console.error('Error message:', error.message);
        }
      }
    };

    if (!requestMadeRef.current) {
      fetchCheckoutRequestId();
      // Set the ref to true after making the request
      requestMadeRef.current = true; 
    }
  }, []);

  useEffect(() => {

    const callbackCreateCustomer = async (tokenId) => {
      const customer = await ConektaApi.createCustomer({
        email: user.email,
        name: user.name,
        phone: user.phone,
        payment_sources: [{
          type: "card",
          token_id: tokenId
        }]
      });
      saveConektaCustomer(customer, tokenId);
    };

    const saveConektaCustomer = async (customer, tokenId) => {
      const response = await createCustomer({
        "userId": user.userId,
        "data": customer,
        "statusId": 1
      });
      if(response.data) await saveSuscription(customer, tokenId);
    }

    const saveSuscription = async (customer) => {
      const response = await ConektaApi.createSubscription(customer.id, {
        card: customer.payment_sources.data[0].id,
        customer_id: customer.id,
        plan: "plan_personal_v1" 
      });
      if(response.id) {
        const result = await createSuscriptions({
          "userId": user.userId,
          "data": response,
          "statusId": 1
        });
        if(result.data){
          setOpen(false);
          triggerRerender();
        }
      }
    }
  
    if (checkoutRequestId) {
      // Check if the script is already loaded
      const existingScript = document.querySelector('script[src="https://pay.conekta.com/v1.0/js/conekta-checkout.min.js"]');
      
      if (!existingScript) {
        const script = document.createElement('script');
        script.src = "https://pay.conekta.com/v1.0/js/conekta-checkout.min.js";
        script.async = true;
        script.onload = () => {
          if (window.ConektaCheckoutComponents) {
            window.ConektaCheckoutComponents.Card({
              targetIFrame: "#conektaIframeContainer",
              allowTokenization: true,
              checkoutRequestId: checkoutRequestId,
              publicKey: process.env.REACT_APP_CONEKTA_PUBLIC_KEY, 
              options: {
                styles: {
                  inputType: 'rounded',
                  buttonType: 'rounded',
                  states: {
                    empty: {
                      borderColor: '#FFAA00'
                    },
                    invalid: {
                      borderColor: '#FF00E0'
                    },
                    valid: {
                      borderColor: '#0079c1'
                    }
                  }
                },
                languaje: 'es',
                button: {
                  colorText: '#ffffff',
                  backgroundColor: '#301007'
                },
                iframe: {
                  colorText: '#65A39B',
                  backgroundColor: '#FFFFFF',
                }
              },
              onCreateTokenSucceeded: function(token) {
                callbackCreateCustomer(token.id);
              },
              onCreateTokenError: function(error) {
                console.log(error);
              }
            });
          }
        };
        document.body.appendChild(script);
      } else {
        if (window.ConektaCheckoutComponents) {
          window.ConektaCheckoutComponents.Card({
            targetIFrame: "#conektaIframeContainer",
            allowTokenization: true,
            checkoutRequestId: checkoutRequestId,
            publicKey: process.env.REACT_APP_CONEKTA_PUBLIC_KEY, // Use your public key from environment variables
            options: {
              styles: {
                inputType: 'rounded',
                buttonType: 'rounded',
                states: {
                  empty: {
                    borderColor: '#FFAA00'
                  },
                  invalid: {
                    borderColor: '#FF00E0'
                  },
                  valid: {
                    borderColor: '#0079c1'
                  }
                }
              },
              languaje: 'es',
              button: {
                colorText: '#ffffff',
                //text: 'Agregar Tarjeta***', // Uncomment and customize the button text if needed
                backgroundColor: '#301007'
              },
              iframe: {
                colorText: '#65A39B',
                backgroundColor: '#FFFFFF'
              }
            },
            onCreateTokenSucceeded: function(token) {
              callbackCreateCustomer(token.id);
            },
            onCreateTokenError: function(error) {
              console.log(error);
            }
          });
        }
      }
    }
  }, [checkoutRequestId, user, setOpen]);

  return (
    <div style={{ height: 520 }}>
      <div id="conektaIframeContainer" style={{ height: '520px' }}></div>
    </div>
  );
};

export default ConektaCheckout;
