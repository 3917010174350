import { useIframeContext } from "../../hooks/useIframeContext";

const IframeComponent = ({ 
  src, 
  width, 
  height = '100%', 
  title, 
  allow, 
  allowFullScreen, 
  style = {
    'zoom': '0.75 !important',
    'MozTransform': 'scale(0.75) !important',
    'MozTransformOrigin': '0 0 !important',
  }, 
  onLoad, 
  className 
}) => {
  const { iframeKey } = useIframeContext();
  return (
    <iframe
      key={iframeKey}
      src={src}
      width={width}
      height={height}
      title={title}
      allow={allow}
      allowFullScreen={allowFullScreen}
      style={style}
      onLoad={onLoad}
      className={className}
    ></iframe>
  );
};

export default IframeComponent;
