import { clsx } from "clsx";
import { Phone, Rocket, Star, Stethoscope, UserRound } from "lucide-react";
import { twMerge } from "tailwind-merge";

export const cn = (...inputs) => {
  return twMerge(clsx(inputs));
};

export const getStatus = (status) => {
  let label = "";
  switch (status) {
    case "paid":
      label = "Pagado";
      break;
    default:
      label = "";
      break;
  }
  return label;
};

export const dateFromUnix = (timestamp) => {
  // Convert to miliseconds
  const date = new Date(timestamp * 1000);

  // Get date
  const year = date.getUTCFullYear();
  const month = (date.getUTCMonth() + 1).toString().padStart(2, "0");
  const day = date.getUTCDate().toString().padStart(2, "0");

  // Format date
  const formattedDate = `${year}-${month}-${day}`;

  return formattedDate;
};

export const formatCurrency = (number) => {
  return new Intl.NumberFormat("es-MX", {
    style: "currency",
    currency: "MXN",
  }).format(number / 100);
};

const _sections = {
  hero: {
    label: "Principal",
    detail: "Sección principal de tu sitio",
    icon: <Rocket className="size-5" />,
  },
  about: {
    label: "Acerca de",
    detail: "Semblanza sobre el doctor",
    icon: <UserRound className="size-5" />,
  },
  services: {
    label: "Servicios",
    detail: "Catálogo de servicios ofrecidos",
    icon: <Stethoscope className="size-5" />,
  },
  reviews: {
    label: "Testimonios",
    detail: "Opiniones y reseñas de tus pacientes",
    icon: <Star className="size-5" />,
  },
  contact: {
    label: "Contacto",
    detail: "Ubicación y contacto",
    icon: <Phone className="size-5" />,
  },
};

export const getSectionDetails = (section) => _sections[section];
