import { useState } from 'react';
import { Loader2 } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Button } from '../../../components/ui/button';
import { Label } from '../../../components/ui/label';
import { activateAccount } from '../../../services/auth';
import { validationSchema }  from './validations';
import {
  Alert,
  AlertDescription,
  AlertTitle,
} from '../../../components/ui/alert';

const ActivateForm = ({ aToken }) => {
  let navigate = useNavigate();
  const [error, setError] = useState('');

  const handleErrors = ({ data }) => {
    const { messages } = data;
    setError(messages?.join(', '));
  }

  const handleSubmit = async (values, { setSubmitting }) => {
    const { token, password } = values;
    try {
      await activateAccount({ token, password });
      setSubmitting(false);
      navigate('/auth/login');
    } catch ({ response }) {
      setSubmitting(false);
      handleErrors(response);
    }
  };

  return (
    <Formik
      initialValues={{ token: aToken, password: '' }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting }) => (
        <Form className="grid gap-4">
          {error && 
            <Alert variant="destructive">
              <AlertTitle>Error</AlertTitle>
              <AlertDescription>
                {error}
              </AlertDescription>
            </Alert>
          }
          <div className="grid gap-2">
            <div className="flex items-center">
              <Label htmlFor="password">Contraseña</Label>
            </div>
            <Field name="password" type="password" required className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline" />
            <ErrorMessage name="password" component="div" className="text-red-500 text-xs italic" />
          </div>
          <div className="flex items-center justify-between">
            <Button type="submit" disabled={isSubmitting} className="w-full">
              Activar cuenta &nbsp;
              {isSubmitting && <Loader2 className="mr-2 h-4 w-4 animate-spin" /> }
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default ActivateForm;
