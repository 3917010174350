import { useEffect, useState } from "react";
import { Button } from "../ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../ui/card";
import { Input } from "../ui/input";
import { Label } from "../ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";
import { updateWebsite } from "../../services/website";
import { Alert, AlertDescription, AlertTitle } from "../ui/alert";
import { AlertCircle } from "lucide-react";

const GeneralProfile = ({ website }) => {
  const [params, setParams] = useState({});
  const [alert, setAlert] = useState({ show: false });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (website?._id) setParams(website);
  }, [website]);

  const onChange = (e) => {
    closeAlert();
    const { name, value } = e.target;
    setParams((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const timeout = setTimeout(() => {
    closeAlert();
  }, 3000);

  const setErrorAlert = () => {
    setAlert({
      description: "Hubo un error intenta mas tarde.",
      title: "Error",
      show: true,
      variant: "destructive",
    });
  };

  const setSuccessAlert = () => {
    setAlert({
      description: "Datos actualizados correctamente",
      title: "Alerta",
      show: true,
      variant: "default",
    });
  };

  const closeAlert = () => {
    setAlert({ show: false });
  };

  const onSubmit = async () => {
    setIsLoading(true);
    const id = params._id;
    const response = await updateWebsite(id, params);
    if (response.status === 200) {
      setSuccessAlert();
    } else {
      setErrorAlert();
    }
    setIsLoading(false);
    return () => clearTimeout(timeout());
  };

  return (
    <div className="grid gap-6 mr-14">
      {alert.show ? (
        <Alert variant={alert.variant}>
          <AlertCircle className="h-4 w-4" />
          <AlertTitle>{alert.title}</AlertTitle>
          <AlertDescription>{alert.description}</AlertDescription>
        </Alert>
      ) : null}
      <Card className="w-full">
        <CardHeader>
          <CardTitle>Configuración General</CardTitle>
          <CardDescription>
            Personaliza la apariencia de tu sitio web.
          </CardDescription>
        </CardHeader>
        <CardContent className="space-y-4">
          <div className="space-y-2">
            <Label htmlFor="primary-color">Color principal</Label>
            <Input
              id="primary-color"
              type="color"
              name="primaryColor"
              value={params.primaryColor}
              onChange={onChange}
            />
          </div>
          <div className="space-y-2">
            <Label htmlFor="secondary-color">Color Secundario</Label>
            <Input
              id="secondary-color"
              type="color"
              name="secondaryColor"
              value={params.secondaryColor}
              onChange={onChange}
            />
          </div>
          <div className="space-y-2">
            <Label htmlFor="font">Tipo de Fuente</Label>
            <Select
              id="font"
              defaultValue={params.fontFamily}
              value={params.fontFamily}
              onValueChange={(e) => {
                onChange({
                  target: {
                    name: "fontFamily",
                    value: e,
                  },
                });
              }}
            >
              <SelectTrigger>
                <SelectValue placeholder="Selecciona una tipografía" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="century-schoolbook">
                  Century Schoolbook
                </SelectItem>
                <SelectItem value="font-grotesk">Grotesk</SelectItem>
                <SelectItem value="helvetica">Helvetica</SelectItem>
                <SelectItem value="lato">Lato</SelectItem>
                <SelectItem value="noto-sans">Noto Sans</SelectItem>
                <SelectItem value="poppins">Poppins</SelectItem>
                <SelectItem value="roboto">Roboto</SelectItem>
              </SelectContent>
            </Select>
          </div>
        </CardContent>
        <CardFooter>
          <Button className="ml-auto" isLoading={isLoading} onClick={onSubmit}>
            Guardar
          </Button>
        </CardFooter>
      </Card>
    </div>
  );
};

export default GeneralProfile;
