import { Link } from 'react-router-dom';
import placeholder from '../assets/img/placeholder.jpg';
import LoginForm from '../components/forms/login/LoginForm';

const Login = () => {
  return (
    <div className="w-full lg:grid lg:min-h-[600px] lg:grid-cols-2 xl:min-h-[800px]">
      <div className="flex items-center justify-center py-12">
        <div className="mx-auto grid w-[350px] gap-6">
          <div className="grid gap-2 text-center">
            <h1 className="text-3xl font-bold">Inicia sesión</h1>
            <p className="text-balance text-muted-foreground">
              Ingresa tu correo para entrar a tu cuenta
            </p>
          </div>
          <LoginForm onLogin={e => {}} />
          <div className="mt-4 text-center text-sm">
            ¿No tienes cuenta?&nbsp;
            <Link to="/sign-up" className="underline">
              Da click aqui
            </Link>
          </div>
        </div>
      </div>
      <div className="hidden bg-muted lg:block">
        <img
          src={placeholder}
          alt="Login background"
          className="h-full w-full object-cover dark:brightness-[0.2] dark:grayscale"
        />
      </div>
    </div>
  );
}

export default Login;