import { useCallback, useEffect, useMemo, useState } from "react";
import { Badge } from "../../../ui/badge";
import {
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../../../ui/card";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../ui/table";
import { fetchConektaCharges } from "../../../../services/conekta";
import { dateFromUnix, formatCurrency, getStatus } from "../../../../lib/utils";

const LeftContainer = ({ customerId }) => {
  const [charges, setCharges] = useState([]);

  const getCharges = useCallback(async () => {
    if (customerId) {
      try {
        const response = await fetchConektaCharges(customerId);
        setCharges(response.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  }, [customerId, setCharges]);

  const shouldFetch = useMemo(() => charges.length === 0, [charges]);

  useEffect(() => {
    if (shouldFetch) {
      getCharges();
    }
  }, [getCharges]);

  return (
    <>
      <CardHeader>
        <CardTitle>Suscripciones</CardTitle>
        <CardDescription>Historial de pagos</CardDescription>
      </CardHeader>
      <CardContent className="space-y-4">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Plan</TableHead>
              <TableHead>Tarjeta</TableHead>
              <TableHead className="hidden sm:table-cell">Estatus</TableHead>
              <TableHead className="hidden md:table-cell">Fecha</TableHead>
              <TableHead className="text-right">Monto (MXN)</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {charges.map((c) => (
              <TableRow key={c.id} className="bg-accent">
                <TableCell>
                  <div className="font-medium">{c.description}</div>
                </TableCell>
                <TableCell>
                  <div className="hidden text-sm text-muted-foreground md:inline">
                    **** **** **** {c?.payment_method?.last4}
                  </div>
                </TableCell>
                <TableCell className="hidden sm:table-cell">
                  <Badge className="text-xs" variant="secondary">
                    {getStatus(c.status)}
                  </Badge>
                </TableCell>
                <TableCell className="hidden md:table-cell">
                  {dateFromUnix(c.created_at)}
                </TableCell>
                <TableCell className="text-right">
                  {formatCurrency(c.amount)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardContent>
    </>
  );
};

export default LeftContainer;
