import { Label } from "../../ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../ui/select";
import { getSectionDetails } from "../../../lib/utils";

const SelectSection = ({ sections, setSection }) => {
  return (
    <div className="grid w-full items-start gap-6">
      <fieldset className="grid gap-6 rounded-lg border p-4">
        <legend className="-ml-1 px-1 text-sm font-medium">Secciones</legend>
        <div className="grid gap-3">
          <Label htmlFor="model">Tipo</Label>
          <Select onValueChange={(e) => setSection(e)}>
            <SelectTrigger
              id="model"
              className="items-start [&_[data-description]]:hidden"
            >
              <SelectValue placeholder="Selecciona una sección" />
            </SelectTrigger>
            <SelectContent>
              {sections?.map((e) => {
                const s = getSectionDetails(e.type);
                return (
                  <SelectItem value={e.sectionId} key={e.sectionId}>
                    <div className="flex items-start gap-3 text-muted-foreground">
                      {s.icon}
                      <div className="grid gap-0.5">
                        <p>{s.label}</p>
                        <p className="text-xs" data-description>
                          {s.detail}
                        </p>
                      </div>
                    </div>
                  </SelectItem>
                );
              })}
            </SelectContent>
          </Select>
        </div>
      </fieldset>
    </div>
  );
};

export default SelectSection;
