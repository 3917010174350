import { useContext, useEffect, useState } from 'react';
import IframeComponent from '../common/iframeComponent';
import HeroForm from './hero/hero.form';
import SelectSection from './select-section/SelectSection';
import { fetchWebsiteContent } from '../../services/website';
import AboutForm from './about/about.form';
import ServicesForm from './services/services.form';
import ReviewsForm from './reviews/reviews.form';
import ContactForm from './conctact/contact.form';
import { SectionAttributeContext } from '../../providers/SectionAttributeProvider';

const WebsiteContent = ({ 
  user,
  isSubmitting
}) => {

  const { setSectionAttribute } = useContext(SectionAttributeContext);
  const [sections, setSections] = useState([]);
  const [website, setWesbite] = useState(null);
  const [section, setSection] = useState('');
  const [sectionType, setSectionType] = useState({});

  useEffect(() => {
    const getWebsiteContent = async () => {
      try {
        const response = await fetchWebsiteContent(user.userId);
        setSections(response.data.sections);
        setWesbite(response.data.url);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    
    if(user) getWebsiteContent();
  }, [user]);

  useEffect(() => {
    const setType = () => {
      setSectionAttribute({});
      setSectionType(sections.filter(e => e.sectionId === section)?.[0]?.type);
    };

    setType();
  }, [sections, section, setSectionAttribute]);

  return (
    <main className="grid flex-1 gap-4 overflow-auto p-4 md:grid-cols-2 lg:grid-cols-3" style={{ minHeight: 630 }}>
      <div
        className="h-full overflow-y-auto relative hidden flex-col items-start gap-8 md:flex" x-chunk="dashboard-03-chunk-0"
      >
        <SelectSection 
          section={section}
          setSection={setSection}
          sections={sections} />
        { sectionType === "hero" && <HeroForm section={section} isSubmitting={isSubmitting } /> }
        { sectionType === "about" && <AboutForm section={section} isSubmitting={isSubmitting } /> }
        { sectionType === "services" && <ServicesForm section={section} isSubmitting={isSubmitting } /> }
        { sectionType === "reviews" && <ReviewsForm section={section} isSubmitting={isSubmitting } /> }
        { sectionType === "contact" && <ContactForm section={section} isSubmitting={isSubmitting } /> }
      </div>
      <div className="relative flex h-full min-h-[50vh] flex-col rounded-xl bg-muted/50 p-4 lg:col-span-2">
        {website && <IframeComponent src={website} />}
      </div>
    </main>
  );
};

export default WebsiteContent;