import { useEffect, useState } from "react";
import { Button } from "../ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../ui/card";
import { Input } from "../ui/input";
import { Label } from "../ui/label";
// import { Switch } from "../ui/switch";
import { updateWebsite } from "../../services/website";
import { AlertCircle } from "lucide-react";
import { Alert, AlertDescription, AlertTitle } from "../ui/alert";

const SocialProfile = ({ website }) => {
  const [params, setParams] = useState({});
  const [alert, setAlert] = useState({ show: false });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (website?._id) setParams(website);
  }, [website]);

  const onChange = (e) => {
    closeAlert();
    const { name, value } = e.target;
    setParams((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const timeout = setTimeout(() => {
    closeAlert();
  }, 3000);

  const setErrorAlert = () => {
    setAlert({
      description: "Hubo un error intenta mas tarde.",
      title: "Error",
      show: true,
      variant: "destructive",
    });
  };

  const setSuccessAlert = () => {
    setAlert({
      description: "Datos actualizados correctamente",
      title: "Alerta",
      show: true,
      variant: "default",
    });
  };

  const closeAlert = () => {
    setAlert({ show: false });
  };

  const onSubmit = async () => {
    setIsLoading(true);
    const id = params._id;
    const response = await updateWebsite(id, params);
    if (response.status === 200) {
      setSuccessAlert();
    } else {
      setErrorAlert();
    }
    setIsLoading(false);
    return () => clearTimeout(timeout());
  };

  return (
    <>
      {alert.show ? (
        <Alert variant={alert.variant}>
          <AlertCircle className="h-4 w-4" />
          <AlertTitle>{alert.title}</AlertTitle>
          <AlertDescription>{alert.description}</AlertDescription>
        </Alert>
      ) : null}
      <Card className="mr-14">
        <CardHeader>
          <CardTitle>Edit Social Profiles</CardTitle>
          <CardDescription>
            Update your social media links and status.
          </CardDescription>
        </CardHeader>
        <CardContent className="space-y-4">
          <div className="grid grid-cols-5 items-center gap-4">
            <Label className="col-span-1" htmlFor="facebook">
              Facebook
            </Label>
            <div className="flex items-center col-span-4 gap-2">
              <Input
                id="facebook"
                type="url"
                name="facebookUrl"
                value={params.facebookUrl}
                onChange={onChange}
                placeholder="https://facebook.com/username"
              />
            </div>
          </div>
          <div className="grid grid-cols-5 items-center gap-4">
            <Label className="col-span-1" htmlFor="twitter">
              Twitter
            </Label>
            <div className="flex items-center col-span-4 gap-2">
              <Input
                id="twitter"
                type="url"
                placeholder="https://instagram.com/username"
                name="twitterUrl"
                value={params.twitterUrl}
                onChange={onChange}
              />
            </div>
          </div>
          <div className="grid grid-cols-5 items-center gap-4">
            <Label className="col-span-1" htmlFor="Instagram">
              Instagram
            </Label>
            <div className="flex items-center col-span-4 gap-2">
              <Input
                id="Instagram"
                type="url"
                placeholder="https://instagram.com/username"
                name="instagramUrl"
                value={params.instagramUrl}
                onChange={onChange}
              />
            </div>
          </div>
          <div className="grid grid-cols-5 items-center gap-4">
            <Label className="col-span-1" htmlFor="tiktok">
              TikTok
            </Label>
            <div className="flex items-center col-span-4 gap-2">
              <Input
                id="tiktok"
                type="url"
                placeholder="https://tiktok.com/@username"
                name="tiktokUrl"
                value={params.tiktokUrl}
                onChange={onChange}
              />
            </div>
          </div>
          <div className="grid grid-cols-5 items-center gap-4">
            <Label className="col-span-1" htmlFor="youtube">
              YouTube
            </Label>
            <div className="flex items-center col-span-4 gap-2">
              <Input
                id="youtube"
                type="url"
                placeholder="https://youtube.com/c/username"
                name="youtubeUrl"
                value={params.youtubeUrl}
                onChange={onChange}
              />
            </div>
          </div>
          <div className="grid grid-cols-5 items-center gap-4">
            <Label className="col-span-1" htmlFor="calendar">
              Calendario
            </Label>
            <div className="flex items-center col-span-4 gap-2">
              <Input
                id="calendarUrl"
                type="url"
                placeholder="https://calendar.com/c/username"
                name="calendarUrl"
                value={params.calendarUrl}
                onChange={onChange}
              />
            </div>
          </div>
        </CardContent>
        <CardFooter className="flex justify-end">
          <Button className="ml-auto" isLoading={isLoading} onClick={onSubmit}>
            Guardar
          </Button>
        </CardFooter>
      </Card>
    </>
  );
};

export default SocialProfile;
