import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Input } from "../../ui/input";
import { Label } from "../../ui/label";
import { Textarea } from "../../ui/textarea";
import Loading from "../../ui/loading";
import { fetchSectionAttribute } from "../../../services/section-attributes";
import { SectionAttributeContext } from "../../../providers/SectionAttributeProvider";

const HeroForm = ({ section, isSubmitting }) => {
  const [isLoading, setIsloading] = useState(true);
  const { sectionAttribute, setSectionAttribute } = useContext(
    SectionAttributeContext
  );

  const getSectionAttribute = useCallback(async () => {
    if (section) {
      try {
        const response = await fetchSectionAttribute(section);
        setSectionAttribute(response.data);
        setIsloading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  }, [section, setSectionAttribute]);

  const shouldFetch = useMemo(
    () => Object.keys(sectionAttribute).length === 0,
    [sectionAttribute]
  );

  useEffect(() => {
    if (shouldFetch) {
      getSectionAttribute();
    }
  }, [shouldFetch, getSectionAttribute]);

  const handleUpdate = (e) => {
    const { name, value } = e.target;
    setSectionAttribute((prevState) => {
      // Split name on levels if is nested, i.e: data.article.title
      const keys = name.split(".");

      // Recursive function to create the new nested state
      const updateNestedState = (obj, keys, value) => {
        const [firstKey, ...restKeys] = keys;
        if (restKeys.length === 0) {
          // last key, update value
          return {
            ...obj,
            [firstKey]: value,
          };
        }
        // No final key, continue to nest
        return {
          ...obj,
          [firstKey]: updateNestedState(obj[firstKey], restKeys, value),
        };
      };

      // If key is nested, start from prevState.data
      if (keys[0] === "data") {
        return {
          ...prevState,
          data: updateNestedState(prevState.data, keys.slice(1), value),
        };
      }

      // If key is not nested, update first level
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  if (isLoading)
    return (
      <div className="w-full">
        <Loading />
      </div>
    );

  return (
    <div className="grid w-full items-start gap-6">
      <fieldset className="grid gap-6 rounded-lg border p-4">
        <legend className="-ml-1 px-1 text-sm font-medium">
          Contenido principal
        </legend>
        <div className="grid gap-3">
          <Label htmlFor="title">Título</Label>
          <Input
            id="title"
            type="text"
            name="data.title"
            placeholder="Escribe el título principal"
            value={sectionAttribute.data?.title}
            onChange={handleUpdate}
            disabled={isSubmitting}
          />
        </div>
        <div className="grid grid-cols-1 gap-4">
          <div className="grid gap-3">
            <Label htmlFor="description">Subtítulo</Label>
            <Textarea
              id="description"
              type="text"
              name="data.description"
              placeholder="Escribe el subtitulo. Recuerda que es la sección principal"
              className="min-h-[4.5rem]"
              value={sectionAttribute.data?.description}
              onChange={handleUpdate}
              disabled={isSubmitting}
            />
          </div>
        </div>
      </fieldset>
      <fieldset className="grid gap-6 rounded-lg border p-4">
        <legend className="-ml-1 px-1 text-sm font-medium">
          Acción principal
        </legend>
        <div className="grid gap-3">
          <Label htmlFor="top-k">Título</Label>
          <Input
            id="data.callToActions.0.title"
            type="text"
            name="data.callToActions.0.title"
            placeholder="Ingresa un titulo para el boton principal."
            value={sectionAttribute.data?.callToActions?.[0]?.title}
            onChange={handleUpdate}
            disabled={isSubmitting}
          />
        </div>
        <div className="grid gap-3">
          <Label htmlFor="top-k">Google Calendar (iframe)</Label>
          <Textarea
            id="data.callToActions.0.href"
            type="text"
            name="data.callToActions.0.href"
            className="min-h-[10.5rem]"
            placeholder="Escribe a donde quieres mandar la acción principal"
            value={sectionAttribute.data?.callToActions?.[0]?.href}
            onChange={handleUpdate}
            disabled={isSubmitting}
          />
        </div>
      </fieldset>
      <fieldset className="grid gap-6 rounded-lg border p-4">
        <legend className="-ml-1 px-1 text-sm font-medium">
          Acción secundaria
        </legend>
        <div className="grid gap-3">
          <Label htmlFor="top-k">Título</Label>
          <Input
            id="data.callToActions.1.title"
            type="text"
            name="data.callToActions.1.title"
            placeholder="Ingresa el título del botón secundario."
            value={sectionAttribute.data?.callToActions?.[1]?.title}
            onChange={handleUpdate}
            disabled={isSubmitting}
          />
        </div>
        <div className="grid gap-3">
          <Label htmlFor="top-k">URL</Label>
          <Input
            id="data.callToActions.1.href"
            type="text"
            name="data.callToActions.1.href"
            placeholder="Ingresa a donde quieres que vaya el botón secundario"
            value={sectionAttribute.data?.callToActions?.[1]?.href}
            onChange={handleUpdate}
            disabled={isSubmitting}
          />
        </div>
      </fieldset>
      <fieldset className="grid gap-6 rounded-lg border p-4">
        <legend className="-ml-1 px-1 text-sm font-medium">
          Contenido adicional
        </legend>
        <div className="grid grid-cols-1 gap-4">
          <div className="grid gap-3">
            <Label htmlFor="data.article.title">Título</Label>
            <Input
              id="data.article.title"
              type="text"
              name="data.article.title"
              placeholder="Escribe un titulo"
              value={sectionAttribute.data?.article?.title}
              onChange={handleUpdate}
              disabled={isSubmitting}
            />
          </div>
          <div className="grid gap-3">
            <Label htmlFor="top-p">Acción adicional</Label>
            <Input
              id="data.article.description"
              type="text"
              name="data.article.description"
              placeholder="Leer más..."
              value={sectionAttribute.data?.article?.description}
              onChange={handleUpdate}
              disabled={isSubmitting}
            />
          </div>
          <div className="grid gap-3">
            <Label htmlFor="top-k">URL adicional</Label>
            <Input
              id="data.article.href"
              type="text"
              name="data.article.href"
              placeholder="Ingresa un link a un artículo"
              value={sectionAttribute.data?.article?.href}
              onChange={handleUpdate}
              disabled={isSubmitting}
            />
          </div>
        </div>
      </fieldset>
    </div>
  );
};

export default HeroForm;
